import React from "react"
import { Image, Text } from "../../Core"
import Button from "../../Buttons/Button"
import "./_insight-sections.scss"

const Websites = ({ section }) => {
  return (
    <div className="insight-websites">
      <Text className="insight-websites__heading" text={section.text} />

      <div className="insight-websites__columns">
        {section.cards.map((website, i) => (
          <a
            href={website.url}
            title="Visit website"
            target="_blank"
            aria-label="Opens in a new tab"
            className="insight-websites__card"
            key={i}>
            <div className="insight-websites__image rounded">
              <Image publicId={website.image} />
            </div>

            <Text as="h4" text={website.name} />
            <Text
              as="p"
              className="insight-websites__btn"
              text="Visit Website"
            />
          </a>
        ))}
      </div>

      {section.buttons && (
        <div className="button-group is-centered mt-3">
          {section.buttons.map((button, i) => (
            <Button key={i} {...button} />
          ))}
        </div>
      )}
    </div>
  )
}

export default Websites
