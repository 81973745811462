import React from "react"
import { Text } from "../../Core"
import "./_insight-sections.scss"
import Button from "../../Buttons/Button"

const MarkdownWithColumns = ({ section }) => {
  return (
    <div className="insight-mwc">
      <Text className="insight-mwc__heading" text={section.text} />
      <div className="insight-mwc__columns">
        {section.columns.map((column, i) => (
          <div key={i} className="insight-mwc__column">
            <Text as="h3" text={`${i + 1}. ${column.heading}`} />
            <Text text={column.text} />
          </div>
        ))}
      </div>

      {section.buttons && (
        <div className="button-group is-centered mt-3">
          {section.buttons.map((button, i) => (
            <Button key={i} {...button} />
          ))}
        </div>
      )}
    </div>
  )
}

export default MarkdownWithColumns
