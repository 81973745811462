import React from "react"
import { Text, Image } from "../../Core"
import Button from "../../Buttons/Button"
import "./_insight-sections.scss"

const EvenColumns = ({ section }) => {
  return (
    <div className="insight-ec">
      {section.text && <Text text={section.text} />}

      <div className={`insight-ec-cols has-${section.columns.length}`}>
        {section.columns.map((column, i) => (
          <div key={i} className="insight-ec-col">
            {column.image && <Image publicId={column.image} />}
            {column.text && <Text text={column.text} />}
            {column.button && column.button.href && (
              <div className="button-group">
                <Button key={i} {...column.button} />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default EvenColumns
