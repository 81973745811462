import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "components/layout"
import SEO from "components/seo"
import { Section, Container } from "../components/Layouts"
import { Image, Text } from "../components/Core"
import { MarketingAnalysisFormTwo } from "../components/Forms"
import TheaterVideo from "../components/Theater/TheaterVideo"
import MarkdownWithColumns from "../components/BodySections/insights/MarkdownWithColumns"
import Steps from "../components/BodySections/insights/Steps"
import Button from "../components/Buttons/Button"
import Reviews from "../components/BodySections/insights/Reviews"
import Websites from "../components/BodySections/insights/Websites"
import Youtube from "../components/Youtube"
import EvenColumns from "../components/BodySections/insights/EvenColumns"

const SingleInsight = ({ data, location }) => {
  const { href, pathname } = location
  const post = data.allInsightsJson.nodes[0]
  const {
    heading,
    heroImage,
    heroMobileImage,
    video,
    sections,
    social,
    createdAt,
    schema
  } = post

  const blogSchema = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    datePublished: createdAt,
    headline: heading
  }

  return (
    <Layout navSpacer className="single-insight">
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        pathname={pathname}
        socialImage={social?.socialImage || ""}
        schemaPage={schema}
      />
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(blogSchema)}</script>
      </Helmet>

      <Section smallSpacing>
        <Container medium>
          <div className="single-insight__hero">
            {/* <time datetime={createdAt.split("T")[0]}>
            {createdAt}
            </time> */}
            <Text as="h1" text={heading} />
            {heroImage && (
              <div className="single-insight__hero-image relative">
                <Image
                  width="652"
                  height="634"
                  wrapperClassName="rounded is-hidden-touch"
                  publicId={heroImage}
                />
                <Image
                  width="390"
                  height="443"
                  wrapperClassName="rounded is-hidden-desktop"
                  publicId={heroMobileImage ? heroMobileImage : heroImage}
                />
                {video && video.code && (
                  <TheaterVideo
                    videoUrl={
                      video.type === "wistia"
                        ? video.code
                        : `https://www.youtube.com/watch?v=${video.code}`
                    }
                    language="en"
                    asWrapper>
                    <button className="reset mt-0 standard-button single-insight__watch-video">
                      Watch Video
                    </button>
                  </TheaterVideo>
                )}
              </div>
            )}
          </div>
        </Container>
      </Section>

      {sections.map((section, i) => {
        if (section.type === "markdown") {
          return (
            <Section key={i} colorBackWhite={section.hasColorBack}>
              <Container medium>
                <Text className="markdown" text={section.text} />

                {section.buttons && (
                  <div className="button-group is-centered mt-3">
                    {section.buttons.map((button, i) => (
                      <Button key={i} {...button} />
                    ))}
                  </div>
                )}
              </Container>
            </Section>
          )
        }

        if (section.type === "markdownWithColumns") {
          return (
            <Section key={i} colorBackWhite={section.hasColorBack}>
              <Container medium>
                <MarkdownWithColumns section={section} />
              </Container>
            </Section>
          )
        }

        if (section.type === "evenColumns") {
          return (
            <Section key={i} colorBackWhite={section.hasColorBack}>
              <Container medium>
                <EvenColumns section={section} />
              </Container>
            </Section>
          )
        }

        if (section.type === "steps") {
          return (
            <Section key={i} colorBackWhite={section.hasColorBack}>
              <Container medium>
                <Steps section={section} />
              </Container>
            </Section>
          )
        }

        if (section.type === "reviews") {
          return (
            <Section key={i} colorBackWhite={section.hasColorBack}>
              <Container medium>
                <Reviews section={section} />
              </Container>
            </Section>
          )
        }

        if (section.type === "websites") {
          return (
            <Section key={i} colorBackWhite={section.hasColorBack}>
              <Container medium>
                <Websites section={section} />
              </Container>
            </Section>
          )
        }
      })}

      <Section>
        <Container>
          <MarketingAnalysisFormTwo
            id="cta-form"
            origin={href}
            phone="(415) 304-8425"
          />
        </Container>
      </Section>
    </Layout>
  )
}

export default SingleInsight

export const pageQuery = graphql`
  query singleInsightPage($title: String!) {
    allInsightsJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        heading
        heroImage
        heroMobileImage
        video {
          code
          type
        }
        sections {
          type
          hasColorBack
          text
          buttons {
            destination
            appearance
            buttonText
            href
          }
          columns {
            heading
            text
            image
            button {
              destination
              appearance
              buttonText
              href
              className
            }
          }
          steps {
            text
          }
          cards {
            image
            name
            practice
            quoteHeading
            quote
            url
          }
        }
        createdAt
        schema
        social {
          socialImage
        }
      }
    }
  }
`
