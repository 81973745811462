import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStar } from "@fortawesome/pro-solid-svg-icons/faStar"
import { Image, Text } from "../../Core"
import Button from "../../Buttons/Button"
import "./_insight-sections.scss"

const Reviews = ({ section }) => {
  return (
    <div className="insight-reviews">
      <Text className="insight-reviews__heading" text={section.text} />
      <div className="insight-reviews__columns">
        {section.cards.map((card, i) => (
          <div key={i} className="insight-reviews__card">
            <div className="insight-reviews__card-header">
              <div className="insight-reviews__card-image">
                <Image publicId={card.image} />
              </div>
              <div>
                <Text as="h4" text={card.name} />
                <Text text={card.practice} />
              </div>
            </div>

            <div className="insight-reviews__stars">
              <div style={{ display: "flex", alignItems: "center" }}>
                <FontAwesomeIcon icon={faStar} color="#ffd700" />
                <FontAwesomeIcon icon={faStar} color="#ffd700" />
                <FontAwesomeIcon icon={faStar} color="#ffd700" />
                <FontAwesomeIcon icon={faStar} color="#ffd700" />
                <FontAwesomeIcon icon={faStar} color="#ffd700" />
              </div>
              <Text as="h5" text={card.quoteHeading} />
            </div>

            <div className="insight-reviews__quote">
              <Text as="p" text={`"${card.quote}"`} />
            </div>
          </div>
        ))}
      </div>

      {section.buttons && (
        <div className="button-group is-centered mt-3">
          {section.buttons.map((button, i) => (
            <Button key={i} {...button} />
          ))}
        </div>
      )}
    </div>
  )
}

export default Reviews
