import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRightLong } from "@fortawesome/pro-light-svg-icons/faArrowRightLong"
import { Text } from "../../Core"
import "./_insight-sections.scss"
import Button from "../../Buttons/Button"

const Steps = ({ section }) => {
  return (
    <div className="insight-steps">
      <Text
        className="insight-steps__heading has-text-centered-desktop"
        text={section.text}
      />
      <div className="insight-steps__columns">
        <div className="insight-steps__step">
          <div className="insight-steps__number">1</div>
          <Text as="h4" text={section.steps[0].text} />
        </div>
        <div className="insight-steps__arrow">
          <FontAwesomeIcon icon={faArrowRightLong} />
        </div>
        <div className="insight-steps__step">
          <div className="insight-steps__number">2</div>
          <Text as="h4" text={section.steps[1].text} />
        </div>
        <div className="insight-steps__arrow">
          <FontAwesomeIcon icon={faArrowRightLong} />
        </div>
        <div className="insight-steps__step">
          <div className="insight-steps__number">3</div>
          <Text as="h4" text={section.steps[2].text} />
        </div>
      </div>

      {section.buttons && (
        <div className="button-group is-centered mt-3">
          <Button {...section.buttons[0]} />
        </div>
      )}
    </div>
  )
}

export default Steps
